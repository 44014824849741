import './App.css';
import React from 'react';
import AppControls from "./components/AppControls";
import Queue from "./components/Queue";
import Profile from "./components/Profile";
import api from './api.js';
import SwipingDemo from './components/SwipingDemo';
import FavoritesDemo from "./components/FavoritesDemo";
import {SharingDemo1, SharingDemo2} from "./components/SharingDemo";
import SharingModal from './components/SharingModal';
import MatchModal from './components/MatchModal';
import * as uuid from 'uuid';
import UnsubscribeModal from "./components/UnsubscribeModal";

const config = require('./config-old.json');

class App extends React.Component {
    constructor(props) {
        super(props);
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`));
        if (localStorage.getItem('app-state')) this.state = JSON.parse(localStorage.getItem('app-state'));
        else this.state = this.freshState;
        if (window.location.pathname === "/unsubscribe") {
            this.state.unsubscribe_modal_open = true;
            window.history.pushState({}, "", "/");
        }
        this.state.screen = this.parseScreen();

        // Deduplicate all lists
        for (let type of ["likes", "dislikes", "rings"]) {
            let collection = this.state[type];
            this.state[type] = collection.filter((a, n) => {
                a.config = config.rings.find(b => b.id === a.id);
                return a && a.id && collection.every((b, m) => a.id === b.id ? m >= n : true);
            });
        }

        // Trim state.rings
        this.state.rings = this.state.rings.slice(Math.max(0, this.state.rings.length - 20), this.state.rings.length);

        // User has been referred
        let partner = new URL(window.location.href).searchParams.get("key");
        if (partner) {
            window.dataLayer.push({
                event: 'event',
                eventProps: {
                    category: "interactions",
                    action: "accepted-invite"
                }
            });
            let name = new URL(window.location.href).searchParams.get("name");
            let email = new URL(window.location.href).searchParams.get("email");
            this.state.user = {
                ...this.state.user,
                name: name,
                email: email
            }
            let currentPartners = this.state.partners ? this.state.partners : [];
            this.state.partners = [...currentPartners.filter(p => p !== partner), partner];
            api.confirmInvite({
                id: this.state.id,
                partner: partner
            }).then(r => window.history.pushState({}, "", window.location.pathname));
        }
        localStorage.setItem('app-state', JSON.stringify(this.state));
    }

    parseScreen() {
        return {
            "/profile": "profile"
        }[window.location.pathname] || "swiping";
    }

    get freshState() {
        return {
            id: uuid.v4(),
            referrer: null,
            screen: "swiping",
            demo_passed: true,
            favorites_demo_passed: true,
            sharing_demo_passed: true,
            app_menu_open: false,
            sharing_modal_open: false,
            unsubscribe_modal_open: false,
            user: {},
            history: [],
            rings: [],
            likes: [],
            partner_likes: [],
            partners: [],
            dislikes: []
        };
    }

    componentDidMount() {
        this.sync();
        window.addEventListener('popstate', () => {
            this.setState({
                screen: this.parseScreen()
            })
        });
        this.updatePath([]);
    }

    updatePath(likedIds) {
        api.getRecommendations(likedIds).then(r => {
            let filteredRecommendationSet =
                r
                    .filter(ring => ![...this.state.rings, ...this.state.likes, ...this.state.dislikes].map(r => r.id).includes(ring.id))
                    .filter(ring => config.rings.some(r => r.id === ring.id) && (ring.config = config.rings.find(r => r.id === ring.id)));
            this.setState({
                rings: [...filteredRecommendationSet, ...this.state.rings]
            });
        });
    }

    shop(ring) {
        if (this.state.rings.length === 0) return;
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "exited-to-product-page"
            }
        });
        let currentRing = this.state.rings[this.state.rings.length - 1];
        window.location.href = `https://manlybands.com/products/${ring.handle || currentRing.handle}`;
    }

    sync() {
        api.syncPartnerData(this.state).then(partnerData => {
            this.setState({
                partner_likes: partnerData.partner_likes || [],
                partners: partnerData.partners || []
            }, this.handlePartnerLikes);
        })
    }

    async handlePartnerLikes() {
        let ringDictionary = [...this.state.rings, ...this.state.likes, ...this.state.dislikes];
        let new_ring_data = {
            rings: [...this.state.rings],
            likes: [...this.state.likes],
            dislikes: [...this.state.dislikes]
        };
        // Strip all PL data from existing rings
        for (let category in new_ring_data) new_ring_data[category].forEach(ring => {
            if (ring.partner_data) delete ring.partner_data;
        });
        // Partner likes that this user hasn't yet judged
        let new_opportunities = this.state.partner_likes.filter(pl => !ringDictionary.some(r => r.id === pl.id));
        // Retrieve the ring data from the API & decorate it with config info
        if (new_opportunities.length > 0) new_opportunities = await api.getRings(new_opportunities.map(pl => pl.id));
        new_opportunities = new_opportunities.filter(ring => config.rings.some(r => r.id === ring.id) && (ring.config = config.rings.find(r => r.id === ring.id)));
        // Place these in state.rings, near the top of the deck
        new_opportunities.forEach(no => new_ring_data.rings.splice(new_ring_data.rings.length - 2, 0, no));
        // Decorate every partner-like entry with a description of who liked it
        for (let category in new_ring_data) new_ring_data[category].forEach(ring => {
            let partner_match = this.state.partner_likes.find(pl => pl.id === ring.id);
            if (partner_match) ring.partner_data = partner_match;
        });
        // Update all decks with this information
        this.setState({
            rings: new_ring_data.rings,
            likes: new_ring_data.likes,
            dislikes: new_ring_data.dislikes
        });
    }

    // User has run out of recommendations. Pick a random like/dislike and get a fresh recommendation set based on that "seed"
    refillQueue() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "ran-out-of-rings"
            }
        });
        let seedCandidates = [...this.state.likes, ...this.state.dislikes];
        let seed = seedCandidates[Math.floor(Math.random() * seedCandidates.length)];
        if (!seed) return;
        this.updatePath([seed.id]);
    }

    ringOp(action, ring) {
        if (!ring) ring = this.state.rings[this.state.rings.length - 1];
        if (action === "dislike") this._dislike(ring.id || ring);
        else if (action === "like") this._like(ring.id || ring);
        else if (action === "back") this._back();
    }

    _back() {
        if (this.state.history.length === 0) return;
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "undo"
            }
        });
        let event = this.state.history.pop();
        let replacement;
        if (event.action === "like" && this.state.likes.length > 0) replacement = this.state.likes.pop();
        else if (event.action === "dislike" && this.state.dislikes.length > 0) replacement = this.state.dislikes.pop();
        this.setState({
            history: this.state.history,
            rings: replacement ? [...this.state.rings, replacement] : this.state.rings,
            likes: this.state.likes,
            dislikes: this.state.dislikes
        }, this.sync)
    }

    _dislike(id) {
        if (this.state.rings.length === 0) return;
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "dislike"
            }
        });
        let currentRing = id ? [...this.state.rings, ...this.state.likes, ...this.state.dislikes].find(r => r.id === id) : this.state.rings.pop();
        this.setState({
            rings: this.state.rings.filter(r => r.id !== currentRing.id),
            dislikes: [...this.state.dislikes.filter(r => r.id !== currentRing.id), currentRing],
            likes: this.state.likes.filter(r => r.id !== currentRing.id),
            history: [...this.state.history, {ring: currentRing, action: 'dislike'}]
        }, this.sync);
    }

    _like(id) {
        if (this.state.rings.length === 0) return;
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "like"
            }
        });
        let currentRing = id ? [...this.state.rings, ...this.state.likes, ...this.state.dislikes].find(r => r.id === id) : this.state.rings.pop();
        if (currentRing.partner_data) this.setState({
            matched_ring: currentRing
        });
        this.updatePath([...this.state.likes, currentRing].map(r => r.id));
        this.setState({
            rings: this.state.rings.filter(r => r.id !== currentRing.id),
            likes: [...this.state.likes.filter(r => r.id !== currentRing.id), currentRing],
            dislikes: this.state.dislikes.filter(r => r.id !== currentRing.id),
            history: [...this.state.history, {ring: currentRing, action: 'like'}]
        }, this.sync)
    }

    changeView() {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                title: this.state.screen === "swiping" ? "profile" : "swiping"
            }
        });
        this.closeSideMenu();
        window.history.pushState({}, "", this.state.screen === "swiping" ? "/profile" : "/");
        this.setState({
            screen: this.state.screen === "swiping" ? "profile" : "swiping"
        }, this.sync);
    }

    closeSideMenu() {
        this.setState({
            app_menu_open: false
        });
    }

    toggleAppMenu() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "opened-side-menu"
            }
        });
        this.setState({
            app_menu_open: !this.state.app_menu_open
        });
    }

    exitApp() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "exited-to-manlybands"
            }
        });
        this.toggleAppMenu();
        window.location.href = "https://manlybands.com";
    }

    startDemo() {
        this.toggleAppMenu();
        this.setState({
            screen: "swiping",
            demo_passed: false,
            favorites_demo_passed: false
        });
    }

    startSharingDemo() {
        this.toggleAppMenu();
        this.setState({
            screen: "swiping",
            sharing_demo_passed: false
        })
    }

    exitDemo(type) {
        if (type === "rings") {
            if (this.state.likes.length === 0) this._like(this.state.rings[this.state.rings.length - 1].id);
            this.setState({
                demo_passed: true
            });
            this.changeView();
        } else if (type === "favorites") {
            this.setState({
                favorites_demo_passed: true
            });
        } else if (type === "sharing-1") {
            this.changeView();
        } else if (type === "sharing-2") {
            this.setState({
                sharing_demo_passed: true
            })
        }
    }

    eraseAppData() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "erased-app-data"
            }
        });
        this.toggleAppMenu();
        this.setState(this.freshState);
        localStorage.removeItem("app-state");
        window.history.pushState({}, "Bandmate", "/");
        window.location.reload(true);
    }

    updateName(name) {
        this.setState({
            user: {...this.state.user, ...{name: name}}
        })
    }

    updateEmail(email) {
        this.setState({
            user: {...this.state.user, ...{email: email}}
        })
    }

    doUnsubscribe() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "unsubscribed"
            }
        });
        this.setState({
            unsubscribe_modal_open: false
        })
        api.unsubscribe(this.state.user).then(r => r);
    }

    inviteMate(data) {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "sent-invite"
            }
        });
        this.setState({
            user: {
                ...this.state.user,
                email: data.sender_email,
                name: data.sender_name
            }
        }, this.sync);
        api.invite({...data, id: this.state.id});
    }

    reviewFavorite(ring) {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "reviewed-favorite"
            }
        });
        this.setState({
            rings: [...this.state.rings.filter(r => r.id !== ring.id), ring]
        });
        this.changeView();
    }

    openSharingModal() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "opened-sharing-modal"
            }
        });
        this.setState({sharing_modal_open: true})
    }

    render() {
        localStorage.setItem("app-state", JSON.stringify(this.state));
        console.log(this.state);
        // Show the user an educational modal about match-sharing if they've judged 4 rings & haven't seen it before
        if (this.state.rings.length === 0 && this.state.demo_passed) this.refillQueue();
        return <React.Fragment>
            <AppControls
                user={this.state.user}
                screen={this.state.screen}
                appMenuOpen={this.state.app_menu_open}
                toggleAppMenu={() => this.toggleAppMenu()}
                changeView={() => this.changeView()}
                exitApp={() => this.exitApp()}
                eraseAppData={() => this.eraseAppData()}
                startDemo={() => this.startDemo()}
                startSharingDemo={() => this.startSharingDemo()}
            />
            <SwipingDemo
                demo_passed={this.state.demo_passed}
                exitDemo={() => this.exitDemo("rings")}
                screen={this.state.screen}
            />
            <FavoritesDemo
                demo_passed={this.state.favorites_demo_passed}
                likes={this.state.likes}
                dislikes={this.state.dislikes}
                exitDemo={() => this.exitDemo("favorites")}
                screen={this.state.screen}
            />
            <SharingDemo1
                screen={this.state.screen}
                demo_passed={this.state.sharing_demo_passed}
                exitDemo={() => this.exitDemo("sharing-1")}
            />
            <SharingDemo2
                screen={this.state.screen}
                demo_passed={this.state.sharing_demo_passed}
                exitDemo={() => this.exitDemo("sharing-2")}
            />
            <SharingModal
                sharingModalOpen={this.state.sharing_modal_open}
                closeSharingModal={() => this.setState({sharing_modal_open: false})}
                share={(d) => this.inviteMate(d)}
                user={this.state.user}
            />
            <UnsubscribeModal
                unsubscribeModalOpen={this.state.unsubscribe_modal_open}
                closeModal={() => this.setState({unsubscribe_modal_open: false})}
                confirmUnsubscribe={() => this.doUnsubscribe()}
                user={this.state.user}
            />
            <MatchModal
                closeModal={() => this.setState({matched_ring: null})}
                matchedRing={this.state.matched_ring}
            />
            <Queue
                screen={this.state.screen}
                like={(ring) => this.ringOp("like", ring)}
                dislike={(ring) => this.ringOp("dislike", ring)}
                back={() => this.ringOp("back")}
                rings={this.state.rings}
                likes={this.state.likes}
                dislikes={this.state.dislikes}
                changeView={() => this.changeView()}
            />
            <Profile
                reviewFavorite={(ring) => this.reviewFavorite(ring)}
                changeView={() => this.changeView()}
                openSharingModal={() => this.openSharingModal()}
                unlike={(ring) => this.ringOp("dislike", ring)}
                screen={this.state.screen}
                likes={this.state.likes}
                style={{display: this.state.screen === "profile" ? "initial" : "none"}}
            />
        </React.Fragment>
    }
}

export default App;
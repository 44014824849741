import React from 'react';
import ManlyDivider from "./ManlyDivider";


export default class UnsubscribeModal extends React.Component {

    close(clickEv, force) {
        if (clickEv.target.classList.contains("modal-wrapper") || force) this.props.closeModal();
    }

    render() {
        return <div onClick={(e) => this.close(e)} className={"modal-wrapper"}
                    style={{display: this.props.unsubscribeModalOpen ? "flex" : "none"}}>
            <div className={"modal flip-away"}>
                <div className={"modal-header"}>
                    <h1 style={{color: "var(--barely-gray)"}}>Unsubscribe?</h1>
                    <ManlyDivider/>
                </div>
                <div style={{margin:"20px"}}>
                    <a onClick={this.props.confirmUnsubscribe} className={"button"} style={{fontSize:"16px"}}>Tap here to unsubscribe</a>
                </div>
                <span style={{color:"gray"}}>Tap anywhere else to close window</span>
            </div>
        </div>;
    }
}
import React from 'react';
import {ReactComponent as Logo} from "./assets/MB-Logo.svg";
import {ReactComponent as Favorites} from "./assets/star.svg";
import {ReactComponent as Rings} from "./assets/ring.svg";
import {ReactComponent as Leave} from "./assets/home.svg";
import {ReactComponent as Delete} from "./assets/trash.svg";
import {ReactComponent as Help} from "./assets/help.svg"
import {ReactComponent as BandmateLogoWide} from "./assets/bandmate-logo.svg";

export default class AppMenu extends React.Component {
    render() {
        return <React.Fragment>
            <div
                onClick={() => this.props.toggleAppMenu()}
                onTouchMove={() => this.props.toggleAppMenu()}
                className={"app-menu"}
            ></div>
            <div
                className={"drawer"}
                onTouchMove={() => this.props.toggleAppMenu()}
            >
                <div className={"drawer-app-title"}>
                    <BandmateLogoWide/>
                </div>
                <div className={"drawer-action-row"}
                     onClick={() => this.props.changeView()}
                >{this.props.screen === "swiping" ? <Favorites/> :
                    <Rings/>}<span>{this.props.screen === "swiping" ? "Favorites" : "The Rings"}</span></div>
                <div className={"drawer-action-row"}
                     onClick={() => this.props.exitApp()}
                ><Leave/><span>manlybands.com</span></div>
                <div className={"drawer-action-row"}
                     onClick={() => this.props.eraseAppData()}
                ><Delete/><span>Clear selections</span></div>
                <div className={"drawer-action-row"}
                     onClick={() => this.props.startDemo()}
                ><Help/><span>How do I bandmate?</span></div>
                <div className={"drawer-action-row"}
                     onClick={() => this.props.startSharingDemo()}
                ><Help/><span>How do I share?</span></div>
                <div className={"drawer-logo"}>POWERED BY<Logo style={{width: "60%"}}/></div>
            </div>
        </React.Fragment>
    }
}
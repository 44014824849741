import React from 'react';
import {ReactComponent as CloseModal} from "./assets/clear.svg";
import {ReactComponent as Arrow} from './assets/arrow.svg';
import ScreenOne from "./assets/BandMate-Invite-0.png";
import ScreenTwo from "./assets/BandMate-Invite-1.png";
import ScreenThree from "./assets/BandMate-Invite-2.png";
import ScreenFour from "./assets/BandMate-Invite-3.png";

export class SharingDemo1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screen: 0,
            screens: [
                ScreenOne,
                ScreenTwo,
                ScreenThree,
                ScreenFour
            ]
        };
    }

    close(clickEv, force) {
        if (force || (this.state.screen === 3 && clickEv.target.classList.contains("modal-wrapper"))) this.props.exitDemo();
    }

    render() {
        return <div onClick={(e) => this.close(e)} className={"modal-wrapper"}
                    style={{display: !this.props.demo_passed && this.props.screen === "swiping" ? "flex" : "none"}}>
            <div className={"modal"}>
                <div style={{display: this.state.screen === 3 ? "block" : "none"}}
                     onClick={() => this.close(null, true)} className={"close-modal"}>
                    <CloseModal/>
                </div>
                <img
                    onClick={() => this.state.screen === 3 ? this.props.exitDemo() : this.setState({screen: this.state.screen + 1})}
                    style={{width: "100%", height: "auto"}} src={this.state.screens[this.state.screen]}/>
            </div>
        </div>;
    }
}

export class SharingDemo2 extends React.Component {
    render() {
        let bottom = null;
        if (document.querySelector(".sharing-header div") && !this.props.demo_passed && this.props.screen === "profile") {
            let rowBottom = document.querySelector(".sharing-header div").getBoundingClientRect().bottom;
            bottom = rowBottom <= 0 ? null : `${rowBottom + 5}px`;
        }
        return bottom && !this.props.demo_passed && this.props.screen === "profile" ?
            <div className={"demo-overlay"} onClick={this.props.exitDemo} onTouchMove={this.props.exitDemo}>
                <Arrow style={{top: bottom}} className={"favorites-demo-arrow"}/>
            </div>
            : null
    }
}
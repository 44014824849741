class api {
    static async getRecommendations(path) {
        let recommendationUrl = `https://api.manlybands.com/bandmate/recommend${path ? '?path=' + encodeURIComponent(JSON.stringify(path)) : ''}`;
        let request = await fetch(recommendationUrl);
        let response = await request.text();
        try {
            let recommendations = JSON.parse(response);
            return recommendations.filter(r => !path.includes(r.id));
        } catch (err) {
            return api._failsafeRecommendations();
        }
    }

    static _failsafeRecommendations() {
        return [{
            "id": 9360543812,
            "variant_id": 38363993039,
            "title": "The Baller",
            "handle": "the-baller",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-8-the-baller-13113665257549.png?v=1575931831",
            "price": "250.00",
            "material": "tungsten",
            "link": "https://manlybands.com/products/the-baller",
            "vendor": "Manly Bands"
        }, {
            "id": 1432533925965,
            "variant_id": 12829224206413,
            "title": "The Zeppelin",
            "handle": "the-zeppelin",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-5-6mm-the-zeppelin-13106627772493.png?v=1575931853",
            "price": "250.00",
            "material": "tungsten",
            "link": "https://manlybands.com/products/the-zeppelin",
            "vendor": "Manly Bands"
        }, {
            "id": 1432566956109,
            "variant_id": 12829431496781,
            "title": "The Ranger",
            "handle": "the-ranger",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-5-the-ranger-12868269244493.png?v=1575931853",
            "price": "300.00",
            "material": "black zirconium",
            "link": "https://manlybands.com/products/the-ranger",
            "vendor": "Manly Bands"
        }, {
            "id": 235413602319,
            "variant_id": 12363757781069,
            "title": "The Elk",
            "handle": "the-elk",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-dark-5-the-elk-13113514655821.png?v=1575931839",
            "price": "250.00",
            "material": "tungsten",
            "link": "https://manlybands.com/products/the-elk",
            "vendor": "Manly Bands"
        }, {
            "id": 9904617871,
            "variant_id": 47565211855,
            "title": "The Hunter",
            "handle": "the-hunter",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-the-hunter-13450224336973.png?v=1575931834",
            "price": "300.00",
            "material": "titanium",
            "link": "https://manlybands.com/products/the-hunter",
            "vendor": "Manly Bands"
        }, {
            "id": 9469368196,
            "variant_id": 43059549519,
            "title": "The Rockstar",
            "handle": "the-rockstar",
            "img": "https://cdn.shopify.com/s/files/1/1569/4617/products/manly-bands-standard-line-the-rockstar-13874183471181.png?v=1577029870",
            "price": "250.00",
            "material": "tungsten",
            "link": "https://manlybands.com/products/the-rockstar",
            "vendor": "Manly Bands"
        }];
    }

    static invite(data) {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute("6Lf2ZhMaAAAAANny7NmQb0mUBU4QF-Kw2pK0bEYV", {action: 'submit'}).then(function (token) {
                fetch('https://api.manlybands.com/bandmate/data', {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        type: 'invite-request',
                        data: {
                            token: token,
                            ...data
                        }
                    })
                }).then(r => r);
            });
        });
    }

    static async getRings(idArray) {
        let recommendationUrl = `https://api.manlybands.com/bandmate/recommend?ids=${idArray.toString()}`;
        let request = await fetch(recommendationUrl);
        let response = await request.text();
        return JSON.parse(response);
    }

    static async confirmInvite(data) {
        return fetch(`https://api.manlybands.com/bandmate/data`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: 'invite-confirm',
                data: data
            })
        });
    }

    static async syncPartnerData(appState) {
        let response = await fetch(`https://api.manlybands.com/bandmate/data`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: 'sync',
                data: appState
            })
        });
        return response.json();
    }

    static unsubscribe(user) {
        return fetch(`https://api.manlybands.com/bandmate/data`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type: 'unsubscribe',
                data: user
            })
        });
    }
}

export default api;
import React from 'react';
import FavoritesRow from './FavoritesRow';

export default class ProfileFavorites extends React.Component {
    render() {
        let rowEntries = [
            ...this.props.likes.filter(r => r.partner_data),
            ...this.props.likes.filter(r => !r.partner_data)
        ];
        let rows = rowEntries.map(ring => <FavoritesRow reviewFavorite={this.props.reviewFavorite}
                                                        unlike={this.props.unlike} key={ring.id} ring={ring}/>);
        if (rows.length === 0) rows =
            <div className={"no-favorites-row"}>You have no favorites<a onClick={this.props.changeView}
                                                                        className={"button"}>Return to rings</a></div>;
        return <div className={"favorites"}>
            <div className={"favorites-navigation"}>
                <div>Favorites <span style={{fontSize: "12px"}}>&</span> Matches</div>
            </div>
            <div className={"favorite-rows"}>
                {rows}
            </div>
        </div>;
    }
}
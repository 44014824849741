import React from 'react';
import RingCard from './RingCard';
import QueueControls from './QueueControls';
import {ReactComponent as Ring} from './assets/ring.svg';

export default class Queue extends React.Component {
    render() {
        let ringCards = this.props.rings.map((ring, index, rings) => {
            return <RingCard
                like={this.props.like}
                dislike={this.props.dislike}
                ring={ring}
                focus={rings[rings.length - 1] === ring}
                key={ring.id}/>
        });
        let no_rings_left = this.props.rings.length === 0;
        return <div className={"queue-view"} style={this.props.screen === "swiping" ? {} : {display: "none"}}>
            <div className={"no-rings-message"}>
                <Ring style={{fill: "rgba(220, 220, 220, 0.6)", height: "20vh", width: "auto"}}/>
                <p>Sorry, we don't have any more recommendations for you</p>
                <p className={no_rings_left ? "t4s fade-in" : "opacity-0"}>Kidding</p>
                <p className={no_rings_left ? "t6s fade-in" : "opacity-0"}>There are always more rings at Manly Bands</p>
                <p className={no_rings_left ? "t8s fade-in" : "opacity-0"}>Loading them now</p>
                <p className={no_rings_left ? "t10s fade-in" : "opacity-0"}>Our rings never ghost anyone</p>
            </div>
            {[ringCards]}
            <QueueControls
                changeView={this.props.changeView}
                rings={this.props.rings}
                likes={this.props.likes}
                dislikes={this.props.dislikes}
                like={this.props.like}
                dislike={this.props.dislike}
                back={this.props.back}
            />
        </div>
    }
}
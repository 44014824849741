import React from 'react';
import { ReactComponent as Logo } from './assets/logo.svg';

export default class ManlyDivider extends React.Component {
    render() {
        return <div className={"manly-divider"}>
            <div></div>
            <Logo/>
        </div>;
    }
}
import React from 'react';
import {ReactComponent as SwipeLeft} from './assets/slide-left-to-shop.svg';
import {ReactComponent as SwipeRight} from './assets/slide-right-to-remove.svg';
import {ReactComponent as TapRow} from './assets/tap-to-visit-profile.svg';

export default class FavoritesDemo extends React.Component {

    render() {
        let bottom = null;
        if (document.querySelector(".favorite-row") && !this.props.demo_passed && this.props.likes.length > 0 && this.props.screen === "profile") {
            let rowBottom = document.querySelector(".favorite-row").getBoundingClientRect().bottom;
            bottom = rowBottom <= 0 ? null : `${rowBottom - 15}px`;
        }
        return bottom && !this.props.demo_passed && this.props.likes.length > 0 && this.props.screen === "profile" ?
            <div className={"demo-overlay"} onClick={this.props.exitDemo} onTouchMove={this.props.exitDemo}>
                <SwipeLeft style={{top: bottom}} className={"favorites-demo-swipeleft"}/>
                <TapRow style={{top: bottom}} className={"favorites-demo-tap-row"}/>
                <SwipeRight style={{top: bottom}} className={"favorites-demo-swiperight"}/>
            </div>
            : null
    }
}
import React from 'react';
import {ReactComponent as HighlightUndo} from './assets/demo-button-highlight-undo-2.svg';
import {ReactComponent as HighlightLike} from './assets/demo-button-highlight-like-2.svg';
import {ReactComponent as HighlightDislike} from './assets/demo-button-highlight-dislike-2.svg';
import {ReactComponent as HighlightFavorites} from './assets/demo-button-highlight-favorites-2.svg';
import {ReactComponent as SwipeLeft} from './assets/slide-left.svg';
import {ReactComponent as SwipeRight} from './assets/slide-right.svg';
import {ReactComponent as TapAnywhere} from './assets/demo-tap-anywhere.svg';

export default class SwipingDemo extends React.Component {

    render() {
        return !this.props.demo_passed && this.props.screen === "swiping" ?
            <div className={"demo-overlay"} onClick={this.props.exitDemo} onTouchMove={this.props.exitDemo}>
                <SwipeLeft className={"demo-swipeleft"}/>
                <TapAnywhere className={"demo-tapanywhere"}/>
                <SwipeRight className={"demo-swiperight"}/>
                <div className={"demo-highlights"}>
                    <HighlightUndo className={"demo-highlight"}/>
                    <HighlightDislike className={"demo-highlight"}/>
                    <HighlightLike className={"demo-highlight"}/>
                    <HighlightFavorites className={"demo-highlight"}/>
                </div>
            </div>
            : null
    }
}
import React from 'react';
import ManlyDivider from "./ManlyDivider";


export default class MatchModal extends React.Component {

    close(clickEv) {
        this.props.closeModal();
    }

    render() {
        let ring = this.props.matchedRing || {
            partner_data: {},
        };
        let mainImage = this.props.matchedRing && ring.config.image_main ? require(`./assets/main_photos/${ring.config.image_main}`).default : null;
        return <div onClick={(e) => this.close(e)} className={"modal-wrapper"}
                    style={{display: this.props.matchedRing ? "flex" : "none"}}>
            <div className={"modal flip-away"}>
                <div className={"modal-header"}>
                    <h1 style={{color: "var(--barely-gray)"}}>Could it be...</h1>
                    <img className={"profile-image"} src={mainImage || ring.img}/>
                    <div>You & <b style={{color: "var(--red)"}}>{ring.partner_data.liked_by_name}</b> both like <b>{ring.title}</b>!</div>
                    <ManlyDivider/>
                </div>
                <div style={{color:"gray"}}>Tap anywhere to continue</div>
            </div>
        </div>;
    }
}
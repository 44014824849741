import React from 'react';
import {ReactComponent as Hamburger} from "./assets/hamburger.svg";
import {ReactComponent as LogoWide} from "./assets/bandmate-logo.svg";
import AppMenu from "./AppMenu";

export default class AppControls extends React.Component {
    render() {
        return <div
            className={"app-controls"}>
            <Hamburger onClick={this.props.toggleAppMenu}/>
            <LogoWide/>
            {this.props.appMenuOpen ? <AppMenu
                user={this.props.user}
                screen={this.props.screen}
                changeView={this.props.changeView}
                exitApp={this.props.exitApp}
                eraseAppData={this.props.eraseAppData}
                startDemo={this.props.startDemo}
                startSharingDemo={this.props.startSharingDemo}
                toggleAppMenu={this.props.toggleAppMenu} /> : null}
        </div>
    }
}
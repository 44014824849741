import React, {Component} from 'react';
import { ReactComponent as Undo } from './assets/undo.svg';
import { ReactComponent as Like } from './assets/like.svg';
import { ReactComponent as Dislike } from './assets/dislike.svg';
import { ReactComponent as Favorites } from './assets/star.svg'

export default class QueueControls extends Component {
    render() {
        let currentRing = this.props.rings[this.props.rings.length - 1];
        let currentRingLiked = currentRing && this.props.likes.some(r => r.id == currentRing.id);
        let currentRingDisliked = currentRing && this.props.dislikes.some(r => r.id == currentRing.id);
        let backAction = currentRingLiked ? this.props.changeView : this.props.back;
        return <div className={"queue-controls"}>
            <div className={"queue-control"} onClick={() => backAction()}>
                <Undo style={{height: "70%"}}/>
            </div>
            <div className={currentRingDisliked ? "control-active queue-control" : "queue-control"} onClick={() => this.props.dislike()}>
                <Dislike style={{height: "70%", color: "var(--red)"}}/>
            </div>
            <div className={currentRingLiked ? "control-active queue-control" : "queue-control"} onClick={() => this.props.like()}>
                <Like style={{height: "70%", color: "var(--red)"}}/>
            </div>
            <div className={"queue-control"} onClick={this.props.changeView}>
                <Favorites style={{height: "70%"}}/>
            </div>
        </div>
    }
}
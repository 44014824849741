import React from 'react';

export default class ProfileSharing extends React.Component {


    render() {
        return <div className={"sharing"}>
            <div className={"sharing-header"}>
                <div onClick={this.props.likes.length === 0 ? () => {} : this.props.openSharingModal} className={`button ${this.props.likes.length === 0 ? 'disabled' : ''}`}>Invite Now</div>
            </div>
        </div>;
    }
}
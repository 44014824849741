import React from 'react';

export default class ImageDots extends React.Component {

    render() {
        let dots = new Array(this.props.count)
            .fill(null)
            .map((image, i) => <span key={i} className={`image-dot ${this.props.focus === i ? "dot-active" : ""}`}></span>);
        return <div className={"image-dots"} style={{display: this.props.display ? "flex" : "none"}}>
            {dots}
        </div>
    }
}
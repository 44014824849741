import React from 'react';
import ProfileSharing from "./ProfileSharing";
import ProfileFavorites from "./ProfileFavorites";
import ManlyDivider from "./ManlyDivider";

export default class Profile extends React.Component {
    render() {
        return <div className={"profile"} style={this.props.screen === "profile" ? {} : {display: "none"}}>
            <ProfileSharing
                likes={this.props.likes}
                openSharingModal={this.props.openSharingModal}
                share={this.props.share}
                updateName={this.props.updateName}
                updateEmail={this.props.updateEmail}
                user={this.props.user}/>
            <ManlyDivider/>
            <ProfileFavorites changeView={this.props.changeView} reviewFavorite={this.props.reviewFavorite} likes={this.props.likes} unlike={this.props.unlike}/>
        </div>
    }
}
import React from 'react';
import ImageDots from "./ImageDots";
import {ReactComponent as Shop} from "./assets/shop-icon-notext.svg";

export default class RingCard extends React.Component {
    constructor(props) {
        super(props);
        let adjectives = ["Esteemed", "Manly", "Devious", "Super", "Elegant", "Nerdy", "Turbo", "Eclectic"];
        let nouns = ["Nerd", "Woodsman", "Accountant", "Man", "Stoic", "Camper", "Gamer"];
        this.state = {
            focus_image: 0,
            backupTagline: adjectives[Math.floor(Math.random() * adjectives.length)] + " " + nouns[Math.floor(Math.random() * nouns.length)],
            touchHistory: [],
            voidTouchTimer: setTimeout(() => {
            }, 0),
            animate_gradient: false,
            swipe_mode: true
        };
    }

    handleTouchMove(ev) {
        let touchHistory = this.state.touchHistory;
        ev.currentTarget.style["transition"] = `unset`;
        let touch = {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY,
            dx: 0,
            dy: 0
        };
        let firstTouchEvent = touchHistory[0] || {x: 0, y: 0};
        let lastTouchEvent = touchHistory[touchHistory.length - 1] || touch;
        touch.dx = lastTouchEvent.dx + (touch.x - lastTouchEvent.x);
        touch.dy = lastTouchEvent.dy + (touch.y - lastTouchEvent.y);
        // 2D vector
        let dx_total = (touch.x - firstTouchEvent.x);
        let dy_total = (touch.y - firstTouchEvent.y);
        if (this.state.swipe_mode && Math.abs(dx_total) > 2 * Math.abs(dy_total)) ev.currentTarget.style["transform"] = `translate(${touch.dx}px) rotate(${touch.dx / 75}deg)`;
        touchHistory.push(touch);
        // TouchEnd may not fire correctly under all situations
        // We want to re-center the card when this happens
        clearTimeout(this.state.voidTouchTimer);
        let el = ev.currentTarget;
        this.setState({
            touchHistory: touchHistory,
            voidTouchTimer: setTimeout(() => {
                el.style["transition"] = `transform 0.2s ease-in`;
                el.style["transform"] = `translate(0px) rotate(0deg)`;
                this.setState({touchHistory: []});
            }, 400)
        });
    };

    handleTouchEnd(ev) {
        let touchHistory = this.state.touchHistory;
        if (touchHistory.length < 2) return this.setState({
            touchHistory: []
        });
        let touchGoalposts = {
            left: 0.9 * window.innerWidth,
            right: window.innerWidth - 0.9 * (window.innerWidth)
        };
        let firstTouch = touchHistory[0];
        let finalTouch = touchHistory[touchHistory.length - 1];
        let path_length = Math.sqrt(Math.pow(finalTouch.x - firstTouch.x, 2) + Math.pow(finalTouch.y - firstTouch.y, 2));

        let dx_total = (finalTouch.x - firstTouch.x);
        let dy_total = (finalTouch.y - firstTouch.y);
        let touch_mostly_horizontal = Math.abs(dx_total) > 2 * Math.abs(dy_total);

        if (!touch_mostly_horizontal) {
            this.setState({
                animate_gradient: true,
                swipe_mode: dy_total > 0
            });
        } else if (!this.state.swipe_mode && touch_mostly_horizontal) {
            let images = [...this.props.ring.config.images, null];
            if (this.props.ring.config.image_main) images.push(null);
            let mod = images.length;
            let nextImage = dx_total < 0 ? (this.state.focus_image + 1) % mod : this.state.focus_image - 1 < 0 ? images.length - 1 : this.state.focus_image - 1;
            this.setState({
                focus_image: nextImage
            })
        } else if (this.state.swipe_mode && path_length > (window.innerWidth / 10) && ((finalTouch.x <= touchGoalposts.left && dx_total < 0) || (finalTouch.x >= touchGoalposts.right && dx_total > 0))) {
            // Continue animation trajectory
            ev.currentTarget.style["transition"] = `transform 0.2s ease-in`;
            ev.currentTarget.style["transform"] = `translate(${(finalTouch.x >= touchGoalposts.right) && dx_total > 0 ? 3 * window.innerWidth : -3 * window.innerWidth}px) rotate(${finalTouch.x >= touchGoalposts.right ? 90 : -90}deg)`;
            let fn = () => (finalTouch.x >= touchGoalposts.right) && dx_total > 0 ? this.props.like(this.props.ring.id) : this.props.dislike(this.props.ring.id);
            setTimeout(fn, 100);
        } else {
            ev.currentTarget.style["transition"] = `transform 0.2s ease-in`;
            ev.currentTarget.style["transform"] = `translate(0px) rotate(0deg)`;
        }
        clearInterval(this.state.voidTouchTimer);
        this.setState({
            touchHistory: [],
            voidTouchTimer: setTimeout(() => {
            }, 0)
        })
    }

    handleClick(ev) {
        if (!this.state.swipe_mode) {
            window.dataLayer.push({
                event: 'event',
                eventProps: {
                    category: "interactions",
                    action: "viewed-ring-profile"
                }
            });
        }
        this.setState({
            animate_gradient: true,
            swipe_mode: !this.state.swipe_mode
        });
    };

    render() {
        let ring = this.props.ring;
        let materials = ring.tags && ring.tags.length > 0 ? ring.tags.split(/\s*,\s*/g).filter(e => e.match(/^material:/)) : [];
        let materialMain = materials.find(m => m.match(/^material:main/));
        if (materialMain) materialMain = materialMain.replace(/material:|main:|secondary:/g, "");
        else if (materials[0]) materialMain = materials[0].replace(/material:|main:|secondary:/g, "");
        else materialMain = null;

        let materialSecondary = materials.find(m => m.match(/^material:secondary:/));
        if (materialSecondary) materialSecondary = materialSecondary.replace(/material:|main:|secondary:/g, "");
        else if (materials[1]) materialSecondary = materials[1].replace(/material:|main:|secondary:/g, "");
        else materialSecondary = null;

        if (materialMain === materialSecondary) materialSecondary = null;

        let images = [...this.props.ring.config.images];
        if (this.props.ring.config.image_main) images.push(this.props.ring.config.image_main);
        images.push(this.props.ring.img);
        let image = images[this.state.focus_image].includes("http") ? images[this.state.focus_image] : require(`./assets/main_photos/${images[this.state.focus_image]}`).default;

        const handlers = {
            onClick: (ev) => this.props.focus && this.handleClick(ev),
            onTouchMove: (ev) => this.props.focus && this.handleTouchMove(ev),
            onTouchEnd: (ev) => this.props.focus && this.handleTouchEnd(ev)
        }

        let registerExit = () => window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "exited-to-product-page"
            }
        });

        return <div {...handlers} className={this.state.swipe_mode ? "ring-card" : "ring-card card-open"}
                    style={this.props.focus ? {willChange: "transform"} : {}}>
            <img src={image}/>
            <a onClick={registerExit} href={ring.link} className={"shop-button"}><Shop/></a>
            <ImageDots display={!this.state.swipe_mode} count={images.length} focus={this.state.focus_image}/>
            <div className={this.state.animate_gradient ? "card-summary animate-gradient" : "card-summary"}>
                <div className={this.state.swipe_mode ? "card-bio-hint" : "display-none"}/>
                <div className={"card-title"}>
                    THE <span>{ring.title.toUpperCase().replace('THE', '')}</span>
                </div>
                <div className={"card-flavor"}>
                    {ring.config && ring.config.tagline ? ring.config["tagline"] : this.state.backupTagline}
                </div>
                <div className={"card-errata"}>
                    <div className={"card-material"}>
                        {materialMain && materialSecondary ? `${materialMain} / ${materialSecondary}` : ""}
                        {materialMain && !materialSecondary ? `${materialMain}` : ""}
                        {!materialMain && !materialSecondary ? `${ring.material}` : ""}
                    </div>
                    <div className={"card-price"}>{this.state.swipe_mode ? "" : "$" + Math.round(+ring.price)}</div>
                </div>
            </div>
            <div className={"card-bio"}
                 dangerouslySetInnerHTML={{__html: (ring.config && ring.config.bio ? ring.config["bio"] : "This is a ring description. It has big ring energy.")}}></div>
        </div>
    }
}
import React from 'react';
import ManlyDivider from "./ManlyDivider";
import {ReactComponent as CloseModal} from "./assets/clear.svg";

export default class SharingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            submitted: false
        };
    }


    close(clickEv, force) {
        if (this.state.submitted || force || clickEv.target.classList.contains("modal-wrapper")) this.props.closeSharingModal();
        this.setState({
            submitting: false,
            submitted: false
        })
    }

    doSubmit(e) {
        e.preventDefault();
        if (this.state.submitting) return false;
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "sent-invite"
            }
        });
        let data = Object.fromEntries(new FormData(e.target));
        this.props.share(data);
        this.setState({
            submitting: true
        });
        setTimeout(() => {
            this.setState({
                submitting: false,
                submitted: true
            })
        }, 2000);
    }

    render() {
        return <div onClick={(e) => this.close(e)} className={"modal-wrapper"}
                    style={{display: this.props.sharingModalOpen ? "flex" : "none"}}>
            <div className={"modal"}>
                <div onClick={() => this.close(null, true)} className={"close-modal"}>
                    <CloseModal/>
                </div>
                <div className={"modal-header"}>
                    <h1 style={{color: "var(--barely-gray)", fontSize: "24px"}}>Get the party started</h1>
                    <ManlyDivider/>
                    <span>{this.state.submitted ? "Invite sent! Tap anywhere to close." : "Invite your significant other. If you two like the same ring, we'll let you know!"}</span>
                </div>
                <form style={this.state.submitted ? {display:"none"}:{}} onSubmit={(e) => this.doSubmit(e)} className={"sharing-form"}>
                    <input disabled={this.state.submitting} name={"recipient_name"} required type={"text"} placeholder={"Their name"}/>
                    <input disabled={this.state.submitting} name={"recipient_email"} required type={"email"} placeholder={"Their email"}/>
                    <input disabled={this.state.submitting} name={"sender_name"} required type={"text"} placeholder={"Your name"}/>
                    <input disabled={this.state.submitting} name={"sender_email"} required type={"email"} placeholder={"Your email"}/>
                    <span className={"form-error"}></span>
                    {/*<div className={"sharing-warranty"}>*/}
                    {/*    <h3 style={{color: "var(--yellow)"}}>- Important -</h3>*/}
                    {/*    <span>We will not share this info with any 3rd party.</span>*/}
                    {/*</div>*/}
                    <input className={this.state.submitting ? "disabled" : ""} type={"submit"} value={this.state.submitting ? "Sending" : "Send"}/>
                </form>
            </div>
        </div>;
    }
}
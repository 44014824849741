import React from 'react';
import {ReactComponent as Match} from "./assets/star.svg";
import {ReactComponent as Shop} from "./assets/shop-icon.svg";
import {ReactComponent as Remove} from "./assets/remove-icon.svg";

export default class FavoritesRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawer: null,
            touchHistory: []
        };
    }

    handleTouchMove(ev) {
        clearTimeout(this.state.resetTimer);
        let touchHistory = this.state.touchHistory;
        let touch = {
            x: ev.touches[0].clientX,
            y: ev.touches[0].clientY,
            dx: 0,
            dy: 0
        };
        let firstTouchEvent = touchHistory[0] || {x: 0, y: 0};
        let lastTouchEvent = touchHistory[touchHistory.length - 1] || touch;
        // 2D vector
        touch.dx = lastTouchEvent.dx + (touch.x - lastTouchEvent.x);
        touch.dy = lastTouchEvent.dy + (touch.y - lastTouchEvent.y);
        let dx_total = (touch.x - firstTouchEvent.x);
        let dy_total = (touch.y - firstTouchEvent.y);
        if (Math.abs(dx_total) > 2 * Math.abs(dy_total)) {
            let offset = 0;
            switch (this.state.drawer) {
                case "favorite-drawer-left":
                    offset = 80;
                    break;
                case "favorite-drawer-right":
                    offset = -80;
                    break;
            }
            ev.currentTarget.style["transform"] = `translate(${touch.dx + offset}px)`;
        }
        touchHistory.push(touch);
        this.setState({
            touchHistory: touchHistory
        });
        ev.currentTarget.style["transition"] = "none";
    }

    handleTouchEnd(ev) {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "opened-favorites-options"
            }
        });
        let touchHistory = this.state.touchHistory;
        if (touchHistory.length < 5) return;
        let firstTouch = touchHistory[0];
        let finalTouch = touchHistory[touchHistory.length - 1];
        let path_length = Math.sqrt(Math.pow(finalTouch.x - firstTouch.x, 2) + Math.pow(finalTouch.y - firstTouch.y, 2));
        let dx_total = (finalTouch.x - firstTouch.x);

        let dy_total = (finalTouch.y - firstTouch.y);
        let touch_mostly_horizontal = Math.abs(dx_total) > 2 * Math.abs(dy_total);
        this.setState({touchHistory: []});
        ev.currentTarget.style["transform"] = null;
        ev.currentTarget.style["transition"] = "transform 0.2s ease-out";
        if (touch_mostly_horizontal) {
            this.setState({
                resetTimer: setTimeout(() => this.setState({drawer: null}), 5000),
                drawer: this.state.drawer ? null : dx_total > 0 ? "favorite-drawer-left" : "favorite-drawer-right"
            });
        }
    }

    exitToProductPage() {
        window.dataLayer.push({
            event: 'event',
            eventProps: {
                category: "interactions",
                action: "exited-to-product-page"
            }
        });
    }

    render() {
        let ring = this.props.ring;
        const handlers = {
            onClick: (ev) => this.props.reviewFavorite(ring),
            onTouchMove: (ev) => this.handleTouchMove(ev),
            onTouchEnd: (ev) => this.handleTouchEnd(ev)
        }
        let materials = ring.tags && ring.tags.length > 0 ? ring.tags.split(/\s*,\s*/g).filter(e => e.match(/^material:/)) : [];
        let materialMain = materials.find(m => m.match(/^material:main/));
        if (materialMain) materialMain = materialMain.replace(/material:|main:|secondary:/g, "");
        else if (materials[0]) materialMain = materials[0].replace(/material:|main:|secondary:/g, "");
        else materialMain = null;

        let materialSecondary = materials.find(m => m.match(/^material:secondary:/));
        if (materialSecondary) materialSecondary = materialSecondary.replace(/material:|main:|secondary:/g, "");
        else if (materials[1]) materialSecondary = materials[1].replace(/material:|main:|secondary:/g, "");
        else materialSecondary = null;

        if (materialMain === materialSecondary) materialSecondary = null;
        
        return <div className={"favorite-row-wrapper"}>
            <a onClick={() => this.exitToProductPage()} rel={"noreferrer"} target={"_blank"} href={`https://manlybands.com/products/${this.props.ring.handle}`}
               className={"favorite-row-shop"}>
                <Shop/>
            </a>
            <div {...handlers}
                 className={`favorite-row ${this.state.drawer ? this.state.drawer : ""} ${this.state.drawer ? "favorite-drawer-open" : ""}`}>
                <div className={"favorite-gutter"}>
                    <Match style={{"display": ring.partner_data ? "initial" : "none"}}/>
                </div>
                <div className={"favorite-content"}>
                    <div className={"favorite-image"}>
                        <img src={ring.img}/>
                    </div>
                    <div className={"favorite-info"}>
                    <span className={"favorite-title"}>{ring.title}{ring.partner_likes ?
                        <Match className={"match-star"}/> : ''}</span>
                        <span
                            className={"favorite-materials"}>
                            {materialMain && materialSecondary ? `${materialMain} / ${materialSecondary}` : ""}
                            {materialMain && !materialSecondary ? `${materialMain}` : ""}
                            {!materialMain && !materialSecondary ? `${ring.material}` : ""}
                        </span>
                        <span className={"favorite-price"}>${(+ring.price).toFixed(0) || "500"}</span>
                    </div>
                </div>
            </div>
            <div className={"favorite-row-remove"}>
                <Remove onClick={() => this.props.unlike(ring)}/>
            </div>
        </div>
    }
}